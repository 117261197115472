.dropzone {
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.161);
  border: 1px dashed rgb(183, 184, 189);
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: rgb(95, 95, 150);
  transition: 0.2s;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
}

.dropHereText {
  pointer-events: none;
}

.dropzone:hover {
  background-color: rgb(223, 222, 231);
}

.dropzone:hover:active {
  background-color: rgb(209, 208, 216);
}

.dropzone.draggingFile {
  background-color: rgb(219, 219, 236);
  border: 7px dashed rgb(171, 172, 194);
  padding: 14px;
}

.dropzone.draggingFile.dragOver {
  background-color: rgb(204, 204, 224);
  border: 7px dashed rgb(150, 151, 173);
  color: rgb(95, 95, 150);
}
